import React from 'react';
//import logo from './s1iwy.JPG';
import {
 Flex,
  Heading,
Image,
//  View,
} from "@aws-amplify/ui-react";
import { Link } from 'react-router-dom';
//import background from "../background3.jpeg";
import background from "../main_background.png";





function Home (){

 // if ((window.matchMedia("(orientation: portrait)").matches)) {var tmpgap = '1rem'} else {tmpgap = '0rem'};
 var tmpgap = '0rem';




	return (<>
	


	<div className='navBarBox' >    <span className="navBarTitle"><Link style={{ textDecoration: 'none', color: 'white' }} to="/">WorkCPU </Link></span>

<Link className='navBarLinkActive' style={{ textDecoration: 'none' }} to="/">  Home</Link>



<a className="navBarLink"
          href="https://app.workcpu.com"
          target="_blank"
          rel="noopener noreferrer"
		  style={{ textDecoration: 'none' }}
        >Login</a>


</div>
<div className='pageContent'>

<div style={{  height: '20%', opacity: '1', backgroundImage: `url(${background})`, backgroundRepeat:"no-repeat",backgroundPosition:"center top",backgroundSize:"cover" ,backgroundAttachment: 'fixed'  }}>


	<br></br>
	<Heading level={2} color='white' >WorkCPU</Heading>
	
	
	<span className='pageContentMed2'>On-Demand Windows Cloud PCs</span>
	<br></br><br></br>
	<Flex
		direction="row"
		justifyContent="center"
		alignItems="center"
		textAlign="center"
		marginLeft='3vw'
		marginRight='3vw'
		
	>
	<span className='pageContentMed2'>BETA Phase - Request Access at <a  style={{ textDecoration: 'none', color: '#fcef42' }} href="mailto:support@workcpu.com">support@workcpu.com</a> </span>
	</Flex>
	<br></br>
	</div>

	<div style={{ backgroundColor:"#000d2b"  }}>
	
	
	
	<Flex 
	color='white' 
	fontSize='30px'
	justifyContent="center"
	alignItems="center"
	textAlign="center"
	minHeight="83px"
	paddingTop = '10px'
	paddingBottom = "10px"
	><span style={{ paddingLeft: '20px', paddingRight: '20px',textDecoration: '' , backgroundColor:"",  textDecorationThickness: '1px'  }}>Get access to a Windows Desktop from any device, anywhere
	</span>
	</Flex>


</div>


<Flex
			direction="column"
			justifyContent="center"
			alignItems="center"
			textAlign="center"
			gap="1.5vh"
			marginTop="1.5vh"
			marginBottom="calc(1.5vh + .4vh)"
			fontSize='14px'
			//	style={{ backgroundImage: `url(${background})`, backgroundRepeat:"no-repeat",backgroundPosition:"center top",backgroundSize:"cover" ,backgroundAttachment: 'fixed' }}
		>



<Flex
			direction="row"
			justifyContent="center"
			alignItems="center"
			textAlign="center"
			gap="0"
			fontSize='23px'		
			color='white'	
		>
		<span>WORKCPU FEATURES:</span>
		
		</Flex>
		<Flex
			direction="row"
			justifyContent="center"
			alignItems="center"
			textAlign="center"
			gap="0"
			fontSize='23px'
		>
		<Flex
			direction="column"
			justifyContent="center"
			alignItems="left"
			textAlign="left"
			//gap="0"
			gap="calc(38px - 6vw)"
			marginLeft='20px'
			marginRight='20px'
			wrap='wrap'
			fontSize='18px'
			boxShadow='inset 0 0 0 1px grey'
			padding='20px'
			backgroundColor='dark grey'
			color='white'
		>
        <span>- Full Windows Desktop with RDP Remote Access: Login and use the Cloud PC as if it were your own local desktop.</span>
        <span>- Access the same WorkCPU Cloud  PC from any device, at home, in the office or on the go.  Your settings and data will follow you.</span>
        <span>- Seperate your Home PC and work data completely and guard your work files behind their own login even on a shared computer.</span>
        <span>- Keep your work data safe in the event your Home PC or Office PC fails during use.</span>
        <span>- No technical skills or hardware maintenance required, the Cloud PC is maintained by WorkCPU and powered by AWS.</span>
        <span>- DIY Software Setup & Install: 100% control over the Cloud PC OS, firewall and installed software.</span>
        <span>- Datacenters are located in the US West and US East regions.</span>


		</Flex>
		</Flex>


		<Flex
			direction="row"
			justifyContent="center"
			alignItems="center"
			textAlign="center"
			gap="0"
			fontSize='23px'
			color='white'
		>
		<span>CLOUD PC SPECS:</span>
		
		</Flex>
		

		<Flex
			direction="row"
			justifyContent="center"
			alignItems="center"
			textAlign="center"
			gap="0"
			fontSize='23px'
		>
		<Flex
			direction="column"
			justifyContent="center"
			alignItems="center"
			textAlign="center"
			//gap="0"
			gap="calc(38px - 6vw)"
			marginLeft='20px'
			marginRight='20px'
			wrap='wrap'
			fontSize='18px'
			boxShadow='inset 0 0 0 1px grey'
			padding='20px'
			backgroundColor='dark grey'
			color='white'
		>
	<span>Desktop1: 1x 2.5GHz vCPU, 4GB RAM - $0.20/hour</span>
	<span>Desktop2: 2x 2.5GHz vCPU, 4GB RAM - $0.30/hour</span>
	<Flex
			direction="column"
			justifyContent="center"
			alignItems="center"
			textAlign="center"
			gap="0"
			fontSize='14px'
			
		>
		<span>*Billed in 1 min increments while cloud pc is running</span>
		</Flex>
		<span>Storage Options: 50GB	- $3.00/mo, 100GB - $5.75/mo</span>
		</Flex>
		</Flex>



		


		




		</Flex>




	</div>

	  </>
		
	
	)
}

export default Home;
