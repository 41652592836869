import React from 'react';
//import logo from './s1iwy.JPG';
import {
  Flex,
  Heading,
  Image,
//  View,
} from "@aws-amplify/ui-react";
import { Link } from 'react-router-dom';
//import background from "../b11c.png";
//import background2 from "../b12.jfif";
//import background3 from "../b13.jfif";
//import background4 from "../b14.jfif";
//import background5 from "../b15.jfif";
//import background6 from "../b16.jfif";
//import background7 from "../b17.jfif";
import background from "./bg4.png";
//import backgroundC from "./a12.png";



function Home (){


 if ((window.matchMedia("(orientation: portrait)").matches)) {var tmpgap = '1rem'} else {tmpgap = '0rem'};
 // var tmpgap = '0rem';

	return (<>
	

	<div className='navBarBox' >    <span className="navBarTitle"><Link style={{ textDecoration: 'none', color: 'white' }} to="/">WorkCPU </Link></span>

<Link className='navBarLink' style={{ textDecoration: 'none' }} to="/">  Home</Link>

<Link className='navBarLinkActive' style={{ textDecoration: 'none' }} to="/about">About</Link>

</div>
<div className='pageContent' >
<div style={{ backgroundColor: "black" , height: '20%', opacity: '1', backgroundImage: `url(${background})`, backgroundRepeat:"no-repeat",backgroundPosition:"center top",backgroundSize:"cover" ,backgroundAttachment: 'fixed'  }}>
<br></br>
<Heading level={2} color="white" >WorkCPU</Heading>
<Flex
		direction="row"
		justifyContent="center"
		alignItems="center"
		textAlign="center"
		marginLeft='3vw'
		marginRight='3vw'
    
    >
<span className='pageContentMed2'>On-Demand Windows Cloud PCs</span>
</Flex>
<br></br>
</div>
	



<Flex
 direction="column"
 justifyContent="center"
 alignItems="center"
 textAlign="center"
>
	
	<div style={{ textAlign: 'left', marginLeft: 'calc(6vw - 10px)',marginRight: 'calc(6vw - 10px)' }}>
<br></br>


	<span className='pageContentMed'>AVAILABLE CONFIGURATIONS</span><br></br>
  <Flex
  direction="row"
  fontSize="16px"
  marginLeft='0px'
  marginRight='0px'
  >
	<Flex
  direction="column"
  marginLeft='10px'
  marginRight='10px'
  wrap='wrap'
  gap={tmpgap}
  >
		<span><span style={{textDecoration: 'underline' }}>Desktop1</span> - 2x 2.5GHz vCPU, 4GB RAM: Basic Windows Desktop - for general use (non CAD) office work.</span>
		</Flex>
    </Flex>



<br></br>

<span className='pageContentMed' >CLOUD PC FEATURES</span><br></br>
<Flex
direction="column"
  marginLeft='10px'
  marginRight='10px'
  wrap='wrap'
  gap={tmpgap}
  >
<span>1) <span style={{textDecoration: 'underline' }}>Full Windows Desktop with RDP Remote Access</span>: Login and use the Cloud PC as if it were your own local desktop.</span>
<span>2) <span style={{textDecoration: 'underline' }}>DIY Software Setup & Install</span>: 100% control over the Cloud PC OS, firewall and installed software.</span>
<span>3) <span style={{textDecoration: 'underline' }}>Built-in Hardware Support</span>: No technical skills or maintenance required, the Cloud PC is maintained by WorkCPU and powered by AWS.</span>
<span>4) <span style={{textDecoration: 'underline' }}>Pay Only For What You Use</span>: Minutes are only consumed while the Cloud PC is actively running.</span>
<span>4) <span style={{textDecoration: 'underline' }}>Automatic PC Backup</span>: The Cloud PC is backed up each time it is shut down and can be rolled back to the previous save.</span>
<span>5) <span style={{textDecoration: 'underline' }}>Fast Software Reset</span>: Reset your Cloud PC to its initial 'just installed' state with the click of a button.</span>
</Flex>
<br></br>

<p hide>
<span className='pageContentMed'>CLOUD PC HARDWARE</span><br></br>
<Flex
  direction="column"
  marginLeft='10px'
  marginRight='10px'
  wrap='wrap'
  gap={tmpgap}
>
<span>CPU: Intel Xeon Platinum 8259CL @ 2.5GHz Base/ 3.1GHz Turbo</span>
<span>STORAGE: 150 IOPS SSD</span>
<span>NOTE: Burst mode style CPU (20% average CPU utilization)</span>
</Flex>
</p>

<br></br>
<br></br>

</div>


</Flex>


</div>



	  </>
		
	
	)
}

export default Home;
